import React from "react";
import Form from 'react-bootstrap/Form';
import { API_URL_GOAL_PHOTO, TOKEN } from '../apis';
import axios from 'axios';
import Button from "react-bootstrap/esm/Button";
import { FaCameraRetro } from "react-icons/fa";
import { success, fail, catch_errors, preloader } from "../Api/RequestFunctions";

class goalPhoto extends React.Component {
    state = {
        photo: null,
        goal_id: this.props.goal_id
    }
    handleChange = (e) => {
        this.setState({
            photo: e.target.files[0]
        })
        console.log(this.state)
    };
    handleSubmit = () => {
        preloader()
        let form_data = new FormData();
        form_data.append('photo', this.state);
        axios.post(`${API_URL_GOAL_PHOTO}`, this.state, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Accept': 'application/json',
                    "Authorization": `Token ${ TOKEN }`
                }
            })
            .catch(function(error) {
                catch_errors(error)
            })
            .then(function(response) {
                if (!response) {
                    fail("Something went wrong...")
                } else if (response.data.success === false) {
                    fail(response.data.message)
                } else {
                    success("You have successfully edited your goal photo", "/home", "successful");
                }
            });
    }

    render() {
        return ( 
            <React.Fragment>
            <form className = "p-5 text-center"
            onSubmit = { this.handleSubmit }>
            <div className = "text-center" >
            <FaCameraRetro size = "50"
            set = "broken"
            className = 'm-lg-3 p-2 active border rounded-circle border-warning' />
            <h4 className = "my-3 text-center bolder" > Change Goal Photo </h4> 
            <Form.Group className = "mb-3 bg-white shadow-sm p-3" >
            <Form.Label > Choose Photo </Form.Label>  
            <Form.Control type = "file"
            id = "photo"
            onChange = {
                this.handleChange
            }
            placeholder = "No image chosen" />
            </Form.Group> 
            <div className = 'row justify-content-center' >
            <h6 id = "errorMessage"
            className = 'py-2 mt-3 rounded border border-danger text-center'
            style = {
                { display: 'none' }
            }> hey </h6> 
            <h6 id = "infoMessage"
            className = 'py-2 mt-3 rounded warning-message text-center'
            style = {
                { display: 'none' }
            } > hey </h6>   
            <Button variant = "warning"
            className = 'shadow text-center'
            id = 'successMessage'
            onClick = { this.handleSubmit }
            type = "button" >
            Save Photo </Button> </div> 
            </div> </form> 
            </React.Fragment>
        );
    }
}
export default goalPhoto;