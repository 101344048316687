export const codes = [
    { "code": "AF", "currency": "AFN" },
    { "code": "AL", "currency": "ALL" },
    { "code": "DZ", "currency": "DZD" },
    { "code": "AD", "currency": "EUR" },
    { "code": "AO", "currency": "AOA" },
    { "code": "AG", "currency": "XCD" },
    { "code": "AR", "currency": "ARS" },
    { "code": "AM", "currency": "AMD" },
    { "code": "AU", "currency": "AUD" },
    { "code": "AT", "currency": "EUR" },
    { "code": "AZ", "currency": "AZN" },
    { "code": "BS", "currency": "BSD" },
    { "code": "BH", "currency": "BHD" },
    { "code": "BD", "currency": "BDT" },
    { "code": "BB", "currency": "BBD" },
    { "code": "BY", "currency": "BYN" },
    { "code": "BE", "currency": "EUR" },
    { "code": "BZ", "currency": "BZD" },
    { "code": "BJ", "currency": "XOF" },
    { "code": "BT", "currency": "BTN" },
    { "code": "BO", "currency": "BOB" },
    { "code": "BA", "currency": "BAM" },
    { "code": "BW", "currency": "BWP" },
    { "code": "BR", "currency": "BRL" },
    { "code": "BN", "currency": "BND" },
    { "code": "BG", "currency": "BGN" },
    { "code": "BF", "currency": "XOF" },
    { "code": "BI", "currency": "BIF" },
    { "code": "CV", "currency": "CVE" },
    { "code": "KH", "currency": "KHR" },
    { "code": "CM", "currency": "XAF" },
    { "code": "CA", "currency": "CAD" },
    { "code": "CF", "currency": "XAF" },
    { "code": "TD", "currency": "XAF" },
    { "code": "CL", "currency": "CLP" },
    { "code": "CN", "currency": "CNY" },
    { "code": "CO", "currency": "COP" },
    { "code": "KM", "currency": "KMF" },
    { "code": "CD", "currency": "CDF" },
    { "code": "CG", "currency": "XAF" },
    { "code": "CR", "currency": "CRC" },
    { "code": "HR", "currency": "HRK" },
    { "code": "CU", "currency": "CUP" },
    { "code": "CY", "currency": "EUR" },
    { "code": "CZ", "currency": "CZK" },
    { "code": "DK", "currency": "DKK" },
    { "code": "DJ", "currency": "DJF" },
    { "code": "DM", "currency": "XCD" },
    { "code": "DO", "currency": "DOP" },
    { "code": "TL", "currency": "USD" },
    { "code": "EC", "currency": "USD" },
    { "code": "EG", "currency": "EGP" },
    { "code": "SV", "currency": "USD" },
    { "code": "GQ", "currency": "XAF" },
    { "code": "ER", "currency": "ERN" },
    { "code": "EE", "currency": "EUR" },
    { "code": "ET", "currency": "ETB" },
    { "code": "FJ", "currency": "FJD" },
    { "code": "FI", "currency": "EUR" },
    { "code": "FR", "currency": "EUR" },
    { "code": "GA", "currency": "XAF" },
    { "code": "GM", "currency": "GMD" },
    { "code": "GE", "currency": "GEL" },
    { "code": "DE", "currency": "EUR" },
    { "code": "GH", "currency": "GHS" },
    { "code": "GR", "currency": "EUR" },
    { "code": "GD", "currency": "XCD" },
    { "code": "GT", "currency": "GTQ" },
    { "code": "GN", "currency": "GNF" },
    { "code": "GW", "currency": "XOF" },
    { "code": "GY", "currency": "GYD" },
    { "code": "HT", "currency": "HTG" },
    { "code": "HN", "currency": "HNL" },
    { "code": "HU", "currency": "HUF" },
    { "code": "IS", "currency": "ISK" },
    { "code": "IN", "currency": "INR" },
    { "code": "ID", "currency": "IDR" },
    { "code": "IR", "currency": "IRR" },
    { "code": "IQ", "currency": "IQD" },
    { "code": "IE", "currency": "EUR" },
    { "code": "IL", "currency": "ILS" },
    { "code": "IT", "currency": "EUR" },
    { "code": "JM", "currency": "JMD" },
    { "code": "JP", "currency": "JPY" },
    { "code": "JO", "currency": "JOD" },
    { "code": "KZ", "currency": "KZT" },
    { "code": "KE", "currency": "KES" },
    { "code": "KI", "currency": "AUD" },
    { "code": "KP", "currency": "KPW" },
    { "code": "KR", "currency": "KRW" },
    { "code": "KW", "currency": "KWD" },
    { "code": "KG", "currency": "KGS" },
    { "code": "LA", "currency": "LAK" },
    { "code": "LV", "currency": "EUR" },
    { "code": "LB", "currency": "LBP" },
    { "code": "LS", "currency": "LSL" },
    { "code": "LR", "currency": "LRD" },
    { "code": "LY", "currency": "LYD" },
    { "code": "LI", "currency": "CHF" },
    { "code": "LT", "currency": "EUR" },
    { "code": "LU", "currency": "EUR" },
    { "code": "MG", "currency": "MGA" },
    { "code": "MW", "currency": "MWK" },
    { "code": "MY", "currency": "MYR" },
    { "code": "MV", "currency": "MVR" },
    { "code": "ML", "currency": "XOF" },
    { "code": "MT", "currency": "EUR" },
    { "code": "MH", "currency": "USD" },
    { "code": "MR", "currency": "MRU" },
    { "code": "MU", "currency": "MUR" },
    { "code": "MX", "currency": "MXN" },
    { "code": "FM", "currency": "USD" },
    { "code": "MD", "currency": "MDL" },
    { "code": "MC", "currency": "EUR" },
    { "code": "MN", "currency": "MNT" },
    { "code": "ME", "currency": "EUR" },
    { "code": "MA", "currency": "MAD" },
    { "code": "MZ", "currency": "MZN" },
    { "code": "MM", "currency": "MMK" },
    { "code": "NA", "currency": "NAD" },
    { "code": "NR", "currency": "AUD" },
    { "code": "NP", "currency": "NPR" },
    { "code": "NL", "currency": "EUR" },
    { "code": "NZ", "currency": "NZD" },
    { "code": "NI", "currency": "NIO" },
    { "code": "NE", "currency": "XOF" },
    { "code": "NG", "currency": "NGN" },
    { "code": "NO", "currency": "NOK" },
    { "code": "OM", "currency": "OMR" },
    { "code": "PK", "currency": "PKR" },
    { "code": "PW", "currency": "USD" },
    { "code": "PA", "currency": "PAB" },
    { "code": "PG", "currency": "PGK" },
    { "code": "PY", "currency": "PYG" },
    { "code": "PE", "currency": "PEN" },
    { "code": "PH", "currency": "PHP" },
    { "code": "PL", "currency": "PLN" },
    { "code": "PT", "currency": "EUR" },
    { "code": "QA", "currency": "QAR" },
    { "code": "RO", "currency": "RON" },
    { "code": "RU", "currency": "RUB" },
    { "code": "RW", "currency": "RWF" },
    { "code": "KN", "currency": "XCD" },
    { "code": "LC", "currency": "XCD" },
    { "code": "VC", "currency": "XCD" },
    { "code": "WS", "currency": "WST" },
    { "code": "SM", "currency": "EUR" },
    { "code": "ST", "currency": "STN" },
    { "code": "SA", "currency": "SAR" },
    { "code": "SN", "currency": "XOF" },
    { "code": "RS", "currency": "RSD" },
    { "code": "SC", "currency": "SCR" },
    { "code": "SL", "currency": "SLL" },
    { "code": "SG", "currency": "SGD" },
    { "code": "SK", "currency": "EUR" },
    { "code": "SI", "currency": "EUR" },
    { "code": "SB", "currency": "SBD" },
    { "code": "SO", "currency": "SOS" },
    { "code": "ZA", "currency": "ZAR" },
    { "code": "SS", "currency": "SSP" },
    { "code": "ES", "currency": "EUR" },
    { "code": "LK", "currency": "LKR" },
    { "code": "SD", "currency": "SDG" },
    { "code": "SR", "currency": "SRD" },
    { "code": "SZ", "currency": "SZL" },
    { "code": "SE", "currency": "SEK" },
    { "code": "CH", "currency": "CHF" },
    { "code": "SY", "currency": "SYP" },
    { "code": "TW", "currency": "TWD" },
    { "code": "TJ", "currency": "TJS" },
    { "code": "TZ", "currency": "TZS" },
    { "code": "TH", "currency": "THB" },
    { "code": "TG", "currency": "XOF" },
    { "code": "TO", "currency": "TOP" },
    { "code": "TT", "currency": "TTD" },
    { "code": "TN", "currency": "TND" },
    { "code": "TR", "currency": "TRY" },
    { "code": "TM", "currency": "TMT" },
    { "code": "TV", "currency": "AUD" },
    { "code": "UG", "currency": "UGX" },
    { "code": "UA", "currency": "UAH" },
    { "code": "AE", "currency": "AED" },
    { "code": "GB", "currency": "GBP" },
    { "code": "US", "currency": "USD" },
    { "code": "UY", "currency": "UYU" },
    { "code": "UZ", "currency": "UZS" },
    { "code": "VU", "currency": "VUV" },
    { "code": "VA", "currency": "EUR" },
    { "code": "VE", "currency": "VES" },
    { "code": "VN", "currency": "VND" },
    { "code": "YE", "currency": "YER" },
    { "code": "ZM", "currency": "ZMW" },
    { "code": "ZW", "currency": "ZWL" }
]