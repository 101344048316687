export const deposits = [
    {
        "user_id": "1219",
        "payment_method": "personal",
        "deposit_amount": "1000",
        "networth": "0",
        "account_type": "basic",
        "currency": "KES",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "fredrickwagwa@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1278",
        "payment_method": "personal",
        "deposit_amount": "4000",
        "networth": "4851",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "3 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "rhonamaria8@gmail.com",
        "updated": "Dec",
        "done": false,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "433",
        "payment_method": "personal",
        "deposit_amount": " 50000",
        "networth": "23100",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "HOLD_TIME ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nakiguliagnes16@gmail.com",
        "updated": "Jun",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1117",
        "payment_method": "personal",
        "deposit_amount": "7500",
        "networth": "10395",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "opiocharles81@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "94",
        "payment_method": "personal",
        "deposit_amount": "4000",
        "networth": "800",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "derickkats725@gmail.com",
        "updated": "Apr",
        "done": true,
        "class_id": "0",
        "date": "2020"
    },
    {
        "user_id": "130",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "11000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "kokas.kokas75@gmail.com",
        "updated": "May",
        "done": true,
        "class_id": "0",
        "date": "2020"
    },
    {
        "user_id": "594",
        "payment_method": "personal",
        "deposit_amount": " 2000",
        "networth": "2811",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "cirusreigns5@gmail.com",
        "updated": "Jun",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "112",
        "payment_method": "personal",
        "deposit_amount": "50000",
        "networth": "1500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "jogwalisaac@gmail.com",
        "updated": "Apr",
        "done": true,
        "class_id": "0",
        "date": "2020"
    },
    {
        "user_id": "45",
        "payment_method": "personal",
        "deposit_amount": "45000",
        "networth": "49500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "5",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "jsjulicool@gmail.com",
        "updated": "Jul",
        "done": true,
        "class_id": "0",
        "date": "2020"
    },
    {
        "user_id": "63",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "25000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "readcissy@gmail.com",
        "updated": "Sep",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "181",
        "payment_method": "personal",
        "deposit_amount": "1000",
        "networth": "326.75",
        "account_type": "basic",
        "currency": "KES",
        "investment_period": "2",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "rajahharun@gmail.com",
        "updated": "Aug",
        "done": true,
        "class_id": "0",
        "date": "2020"
    },
    {
        "user_id": "179",
        "payment_method": "personal",
        "deposit_amount": "200000",
        "networth": "18100",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mugabepatricia@gmail.com",
        "updated": "Jul",
        "done": true,
        "class_id": "0",
        "date": "2020"
    },
    {
        "user_id": "180",
        "payment_method": "personal",
        "deposit_amount": "200000",
        "networth": "3000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mmugabe792@gmail.com",
        "updated": "Jul",
        "done": true,
        "class_id": "0",
        "date": "2020"
    },
    {
        "user_id": "195",
        "payment_method": "personal",
        "deposit_amount": "100000",
        "networth": "3925",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "ngdsheeba@gmail.com",
        "updated": "Sep",
        "done": true,
        "class_id": "0",
        "date": "2020"
    },
    {
        "user_id": "222",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "22000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "asiimwegadafi71@gmail.com",
        "updated": "Sep",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "227",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "11000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "shilatashphine@gmail.com",
        "updated": "Oct",
        "done": true,
        "class_id": "0",
        "date": "2020"
    },
    {
        "user_id": "283",
        "payment_method": "personal",
        "deposit_amount": "15000",
        "networth": "16500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mulambuzimark1@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2020"
    },
    {
        "user_id": "0",
        "payment_method": "personal",
        "deposit_amount": "500",
        "networth": "550",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "10",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "isaacmuwanguzi180@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2020"
    },
    {
        "user_id": "0",
        "payment_method": "personal",
        "deposit_amount": "50",
        "networth": "",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "isaacmuwanguzi180@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2020"
    },
    {
        "user_id": "190",
        "payment_method": "personal",
        "deposit_amount": "50000",
        "networth": "55000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "2",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "ediccalinton@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2020"
    },
    {
        "user_id": "171",
        "payment_method": "personal",
        "deposit_amount": "150000",
        "networth": "1045",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "atulindaderek@gmail.com",
        "updated": "Jun",
        "done": true,
        "class_id": "0",
        "date": "2020"
    },
    {
        "user_id": "433",
        "payment_method": "personal",
        "deposit_amount": "150000",
        "networth": "23100",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nakiguliagnes16@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "202",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "750",
        "account_type": "basic",
        "currency": "NGN",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "diakpomrerej@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "470",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "4200",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "aloxiouson1@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "490",
        "payment_method": "personal",
        "deposit_amount": "200000",
        "networth": "242550",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "10",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "wadrobrian@gmail.com",
        "updated": "Apr",
        "done": false,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "792",
        "payment_method": "personal",
        "deposit_amount": "3000",
        "networth": "3638",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "3 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "atuhweradavis@gmail.com",
        "updated": "Dec",
        "done": false,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "68",
        "payment_method": "personal",
        "deposit_amount": "340000",
        "networth": "42000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nalongoreginahmirembe@gmail.com",
        "updated": "Apr",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1219",
        "payment_method": "personal",
        "deposit_amount": "1000",
        "networth": "0",
        "account_type": "basic",
        "currency": "KES",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "fredrickwagwa@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "623",
        "payment_method": "personal",
        "deposit_amount": " 3000",
        "networth": "4429",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "cantianallan@gmail.com",
        "updated": "Aug",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "649",
        "payment_method": "personal",
        "deposit_amount": "136000",
        "networth": "14000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "stasssavings@gmail.com",
        "updated": "Aug",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "654",
        "payment_method": "personal",
        "deposit_amount": "103000",
        "networth": "111250",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "alvincarol991@gmail.com",
        "updated": "Aug",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1149",
        "payment_method": "personal",
        "deposit_amount": "2500",
        "networth": "2750",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "hazelpatel0@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1022",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2200",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "basajjaadan@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1209",
        "payment_method": "personal",
        "deposit_amount": "3000",
        "networth": "3300",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nuwagabacarol@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "716",
        "payment_method": "personal",
        "deposit_amount": " 60",
        "networth": "85",
        "account_type": "basic",
        "currency": "KES",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "misati26@gmail.com",
        "updated": "Oct",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "169",
        "payment_method": "personal",
        "deposit_amount": "1800",
        "networth": "2083",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "imuwanguzi616@gmail.com",
        "updated": "Oct",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "696",
        "payment_method": "personal",
        "deposit_amount": "9500",
        "networth": "11000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mwanjejoel9@gmail.com",
        "updated": "Oct",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "744",
        "payment_method": "personal",
        "deposit_amount": " 3000",
        "networth": "4000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "2 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "herbertfx44@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "789",
        "payment_method": "personal",
        "deposit_amount": " 200000",
        "networth": "225138",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "fredtumwiine6@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "500",
        "payment_method": "personal",
        "deposit_amount": "8",
        "networth": "9",
        "account_type": "basic",
        "currency": "ZMW",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "famajila3@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "870",
        "payment_method": "personal",
        "deposit_amount": " 20000",
        "networth": "22000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "kukundadi@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "864",
        "payment_method": "personal",
        "deposit_amount": " 4000",
        "networth": "5355",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "jamalxaviers25@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "897",
        "payment_method": "personal",
        "deposit_amount": " 10000",
        "networth": "11000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "egonamek112@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "933",
        "payment_method": "personal",
        "deposit_amount": "9500",
        "networth": "10450",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nakayiwabridget5@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "169",
        "payment_method": "personal",
        "deposit_amount": " 2000",
        "networth": "2430",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "imuwanguzi616@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "947",
        "payment_method": "personal",
        "deposit_amount": "15000",
        "networth": "3000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "aruhocanan@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "797",
        "payment_method": "personal",
        "deposit_amount": " 7.5",
        "networth": "8.25",
        "account_type": "basic",
        "currency": "ZMW",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "sebastianmbonabi@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "975",
        "payment_method": "personal",
        "deposit_amount": "5",
        "networth": "5",
        "account_type": "basic",
        "currency": "ZMW",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "oswardmusonda2002@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "0",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "20948",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "isaacmuwanguzi180@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1022",
        "payment_method": "personal",
        "deposit_amount": " 20000",
        "networth": "22000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "basajjaadan@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1006",
        "payment_method": "personal",
        "deposit_amount": " 2000",
        "networth": "2200",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "Nkojjonelson25@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1014",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "3100",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "luyombyaian98@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1029",
        "payment_method": "personal",
        "deposit_amount": " 10000",
        "networth": "11000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "jamiranakiyingi@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1029",
        "payment_method": "personal",
        "deposit_amount": " 10000",
        "networth": "11000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "jamiranakiyingi@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "870",
        "payment_method": "personal",
        "deposit_amount": " 40000",
        "networth": "44000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "kukundadi@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1014",
        "payment_method": "personal",
        "deposit_amount": " 2000",
        "networth": "3100",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "luyombyaian98@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1023",
        "payment_method": "personal",
        "deposit_amount": " 2000",
        "networth": "3100",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "ssebaddukarodney9@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1033",
        "payment_method": "personal",
        "deposit_amount": " 2000",
        "networth": "3100",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "johnroymutaawe@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "991",
        "payment_method": "personal",
        "deposit_amount": " 2000",
        "networth": "3000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "markganazi6@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "169",
        "payment_method": "personal",
        "deposit_amount": " 2000",
        "networth": "2315",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "3 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "imuwanguzi616@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1",
        "payment_method": "personal",
        "deposit_amount": "150000",
        "networth": "390000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "wasswaviannie@gmail.com",
        "updated": "May",
        "done": true,
        "class_id": "0",
        "date": "2020"
    },
    {
        "user_id": "991",
        "payment_method": "personal",
        "deposit_amount": " 9500",
        "networth": "10534",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "markganazi6@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "0",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "20948",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "isaacmuwanguzi180@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1060",
        "payment_method": "personal",
        "deposit_amount": " 5000",
        "networth": "5500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "abiolahakanyijuka@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1020",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "22000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "kizzasamuel017@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "809",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "22000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "johnsonsur84@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "797",
        "payment_method": "personal",
        "deposit_amount": "2",
        "networth": "2.2",
        "account_type": "basic",
        "currency": "ZMW",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "sebastianmbonabi@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1008",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "22000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "Nkojjodavid19@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1296",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "11550",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "markhans977@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "744",
        "payment_method": "personal",
        "deposit_amount": "100000",
        "networth": "115130",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "herbertfx44@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "975",
        "payment_method": "personal",
        "deposit_amount": "5",
        "networth": "5",
        "account_type": "basic",
        "currency": "ZMW",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "oswardmusonda2002@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "40",
        "payment_method": "personal",
        "deposit_amount": "8000",
        "networth": "9000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nakityobarbrs@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "843",
        "payment_method": "personal",
        "deposit_amount": "500",
        "networth": "2310",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "kbrgmichael@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "870",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "11000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "kukundadi@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1136",
        "payment_method": "personal",
        "deposit_amount": "50000",
        "networth": "65000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "hakizimanachristopher2@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1060",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "5500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "abiolahakanyijuka@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "169",
        "payment_method": "personal",
        "deposit_amount": "1400",
        "networth": "1620",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "imuwanguzi616@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "933",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "11000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nakayiwabridget5@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1056",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "5500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "2 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "ekayongo5@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1247",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "11550",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "tumwinepiuslucas1@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1118",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "4620",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "jerryembati6@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1209",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "22000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nuwagabacarol@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1118",
        "payment_method": "personal",
        "deposit_amount": "1500",
        "networth": "4620",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "jerryembati6@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1219",
        "payment_method": "personal",
        "deposit_amount": "1000",
        "networth": "0",
        "account_type": "basic",
        "currency": "KES",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "fredrickwagwa@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1269",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "1155",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "sheenanakaye19@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1267",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "ssekibaalashafik@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1242",
        "payment_method": "personal",
        "deposit_amount": "1000",
        "networth": "2310",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "cissynabimanya@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1269",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "1155",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "sheenanakaye19@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "933",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "11000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nakayiwabridget5@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "946",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2310",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "desiretee42@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1267",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "ssekibaalashafik@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1029",
        "payment_method": "personal",
        "deposit_amount": "9000",
        "networth": "9900",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "jamiranakiyingi@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1271",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "23100",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "ronaldka90@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1056",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "5500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "ekayongo5@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1149",
        "payment_method": "personal",
        "deposit_amount": "6500",
        "networth": "7150",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "hazelpatel0@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "68",
        "payment_method": "personal",
        "deposit_amount": "45000",
        "networth": "42000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nalongoreginahmirembe@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1212",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "5500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "roymrtn10@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1284",
        "payment_method": "personal",
        "deposit_amount": "1000",
        "networth": "1155",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "malenathan1@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1085",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "23100",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "genrobinahwinnie2002@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "843",
        "payment_method": "personal",
        "deposit_amount": "500",
        "networth": "2310",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "kbrgmichael@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "0",
        "payment_method": "personal",
        "deposit_amount": "1000",
        "networth": "1214",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "isaacmuwanguzi180@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "933",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "5500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nakayiwabridget5@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1020",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2200",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "kizzasamuel017@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "0",
        "payment_method": "personal",
        "deposit_amount": "14000",
        "networth": "10948",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "isaacmuwanguzi180@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1200",
        "payment_method": "personal",
        "deposit_amount": "10",
        "networth": "11",
        "account_type": "basic",
        "currency": "ZMW",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "ericmulengazambia@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1108",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "5500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "anabumboirene@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1149",
        "payment_method": "personal",
        "deposit_amount": "18000",
        "networth": "19800",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "hazelpatel0@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1006",
        "payment_method": "personal",
        "deposit_amount": "3000",
        "networth": "3300",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "Nkojjonelson25@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1149",
        "payment_method": "personal",
        "deposit_amount": "2500",
        "networth": "2750",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "hazelpatel0@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1108",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "11000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "anabumboirene@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "777",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2310",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "newlexasmart@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "843",
        "payment_method": "personal",
        "deposit_amount": "500",
        "networth": "2310",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "kbrgmichael@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "797",
        "payment_method": "personal",
        "deposit_amount": "3",
        "networth": "3.3",
        "account_type": "basic",
        "currency": "ZMW",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "sebastianmbonabi@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1307",
        "payment_method": "personal",
        "deposit_amount": "100000",
        "networth": "115500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "hikmahkisingo@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "0",
        "payment_method": "personal",
        "deposit_amount": "18000",
        "networth": "20948",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "isaacmuwanguzi180@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1149",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2200",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "hazelpatel0@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1280",
        "payment_method": "personal",
        "deposit_amount": "500",
        "networth": "578",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "washowamassape@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1209",
        "payment_method": "personal",
        "deposit_amount": "15000",
        "networth": "16500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nuwagabacarol@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1225",
        "payment_method": "personal",
        "deposit_amount": "50000",
        "networth": "57750",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "baigaaskjoseph@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1212",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "10395",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "roymrtn10@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1267",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "ssekibaalashafik@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "831",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "1155",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mwesigwa447@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1344",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "11550",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "onadit@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "831",
        "payment_method": "personal",
        "deposit_amount": "500",
        "networth": "1155",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mwesigwa447@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "831",
        "payment_method": "personal",
        "deposit_amount": "500",
        "networth": "1155",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mwesigwa447@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "843",
        "payment_method": "personal",
        "deposit_amount": "500",
        "networth": "2310",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "kbrgmichael@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1117",
        "payment_method": "personal",
        "deposit_amount": "9000",
        "networth": "10395",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "opiocharles81@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "831",
        "payment_method": "personal",
        "deposit_amount": "1000",
        "networth": "1155",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mwesigwa447@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1350",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "11550",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "okelloemmagad24@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1209",
        "payment_method": "personal",
        "deposit_amount": "35000",
        "networth": "38500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nuwagabacarol@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "933",
        "payment_method": "personal",
        "deposit_amount": "6000",
        "networth": "6600",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nakayiwabridget5@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1269",
        "payment_method": "personal",
        "deposit_amount": "1000",
        "networth": "1155",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "sheenanakaye19@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "947",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "3000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "aruhocanan@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "947",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "3000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "aruhocanan@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1360",
        "payment_method": "personal",
        "deposit_amount": "3000",
        "networth": "3465",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "trevorkirunda6@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "992",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "24255",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "2 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nahabwerichard0@gmail.com",
        "updated": "Dec",
        "done": false,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1362",
        "payment_method": "personal",
        "deposit_amount": "1000",
        "networth": "578",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "badisaffy3@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1369",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2310",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "www.kiyinginicholas20@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1371",
        "payment_method": "personal",
        "deposit_amount": "1000",
        "networth": "1155",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "jktrevora14@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "433",
        "payment_method": "personal",
        "deposit_amount": "30000",
        "networth": "23100",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nakiguliagnes16@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1209",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "22000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nuwagabacarol@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1310",
        "payment_method": "personal",
        "deposit_amount": "60000",
        "networth": "23100",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "sirajekaks@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1322",
        "payment_method": "personal",
        "deposit_amount": "4000",
        "networth": "4200",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mandelabarungi@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "68",
        "payment_method": "personal",
        "deposit_amount": "55000",
        "networth": "42000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nalongoreginahmirembe@gmail.com",
        "updated": "Apr",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1117",
        "payment_method": "personal",
        "deposit_amount": "2500",
        "networth": "3000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "opiocharles81@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1367",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "5600",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "henrymuhanguzi09@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1322",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2100",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mandelabarungi@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1362",
        "payment_method": "personal",
        "deposit_amount": "500",
        "networth": "578",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "badisaffy3@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1212",
        "payment_method": "personal",
        "deposit_amount": "9000",
        "networth": "10000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "roymrtn10@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1006",
        "payment_method": "personal",
        "deposit_amount": "3500",
        "networth": "3850",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "Nkojjonelson25@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "843",
        "payment_method": "personal",
        "deposit_amount": "1100",
        "networth": "2000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "kbrgmichael@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1006",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2200",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "Nkojjonelson25@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1322",
        "payment_method": "personal",
        "deposit_amount": "4000\r\n",
        "networth": "4200",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mandelabarungi@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1422",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "6000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "magy.julius@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1310",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "23100",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "sirajekaks@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1432",
        "payment_method": "personal",
        "deposit_amount": "3000",
        "networth": "3150",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "oericisaac@gmail.com",
        "updated": "Apr",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1008",
        "payment_method": "personal",
        "deposit_amount": "6000",
        "networth": "8000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "Nkojjodavid19@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1267",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "ssekibaalashafik@gmail.com",
        "updated": "Apr",
        "done": true,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "1322",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2100",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mandelabarungi@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1",
        "payment_method": "personal",
        "deposit_amount": "670000",
        "networth": "2010000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "wasswaviannie@gmail.com",
        "updated": "Nov",
        "done": true,
        "class_id": "4",
        "date": "2022"
    },
    {
        "user_id": "1008",
        "payment_method": "personal",
        "deposit_amount": "3200",
        "networth": "3520",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "Nkojjodavid19@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1006",
        "payment_method": "personal",
        "deposit_amount": "3000",
        "networth": "3300",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "Nkojjonelson25@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1442",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2426",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "10",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "sklegesi@gmail.com",
        "updated": "Jan",
        "done": false,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "68",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "21000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nalongoreginahmirembe@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1322",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "5500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "0",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mandelabarungi@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1367",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "11550",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "henrymuhanguzi09@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1453",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "5775",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "lutaayaraymondrayray@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1322",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "5512",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mandelabarungi@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1466",
        "payment_method": "personal",
        "deposit_amount": "15000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nsubugaedrisa@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1466",
        "payment_method": "personal",
        "deposit_amount": "4500",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nsubugaedrisa@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1461",
        "payment_method": "personal",
        "deposit_amount": "4000",
        "networth": "12128",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "fridahmareawiny@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1298",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2426",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "jordanashaba98@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1466",
        "payment_method": "personal",
        "deposit_amount": "8000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nsubugaedrisa@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1461",
        "payment_method": "personal",
        "deposit_amount": "12000",
        "networth": "12128",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "fridahmareawiny@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1474",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2426",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nalwaddalynet@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1461",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "12128",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "fridahmareawiny@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1422",
        "payment_method": "personal",
        "deposit_amount": "50000",
        "networth": "57750",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "2",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "magy.julius@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1466",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nsubugaedrisa@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1466",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nsubugaedrisa@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1466",
        "payment_method": "personal",
        "deposit_amount": "3000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nsubugaedrisa@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1466",
        "payment_method": "personal",
        "deposit_amount": "6000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nsubugaedrisa@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1466",
        "payment_method": "personal",
        "deposit_amount": "9000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nsubugaedrisa@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1466",
        "payment_method": "personal",
        "deposit_amount": "7000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nsubugaedrisa@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1495",
        "payment_method": "personal",
        "deposit_amount": "50000",
        "networth": "60638",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mikekenzi1995@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "191",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "18191",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "eyerfortunate@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1322",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2205",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mandelabarungi@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "191",
        "payment_method": "personal",
        "deposit_amount": "9000",
        "networth": "18191",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "eyerfortunate@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1466",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nsubugaedrisa@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1427",
        "payment_method": "personal",
        "deposit_amount": "3000",
        "networth": "3638",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "yigatonnie@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1509",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2426",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "lowenah10@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1322",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "5512",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mandelabarungi@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1006",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "5500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "Nkojjonelson25@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1008",
        "payment_method": "personal",
        "deposit_amount": "3000",
        "networth": "3300",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "Nkojjodavid19@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "15000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "wasswaviannie@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "3",
        "date": "2022"
    },
    {
        "user_id": "1510",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "haggena93@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1509",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2426",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "lowenah10@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1482",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2426",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "resdanielz@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1482",
        "payment_method": "personal",
        "deposit_amount": "3000",
        "networth": "2426",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "resdanielz@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "68",
        "payment_method": "personal",
        "deposit_amount": "15000",
        "networth": "15750",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nalongoreginahmirembe@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "0",
        "payment_method": "personal",
        "deposit_amount": "50000",
        "networth": "20948",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "isaacmuwanguzi180@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1482",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2426",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "resdanielz@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1482",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2426",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "resdanielz@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "169",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2100",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "imuwanguzi616@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "68",
        "payment_method": "personal",
        "deposit_amount": "15000",
        "networth": "15750",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nalongoreginahmirembe@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "947",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "3000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "aruhocanan@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1267",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "ssekibaalashafik@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1322",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "5250",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mandelabarungi@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1399",
        "payment_method": "personal",
        "deposit_amount": "1000",
        "networth": "1213",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "3 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "joshuamayeku@yahoo.com",
        "updated": "Feb",
        "done": false,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1108",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "22000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "anabumboirene@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "68",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "21000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nalongoreginahmirembe@gmail.com",
        "updated": "Jul",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1322",
        "payment_method": "personal",
        "deposit_amount": "3000",
        "networth": "3150",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mandelabarungi@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1322",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2100",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "2 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mandelabarungi@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1255",
        "payment_method": "personal",
        "deposit_amount": "5",
        "networth": "5",
        "account_type": "basic",
        "currency": "KES",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "casillajoseph@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1149",
        "payment_method": "personal",
        "deposit_amount": "1000",
        "networth": "1100",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "hazelpatel0@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "0",
        "payment_method": "personal",
        "deposit_amount": "8500",
        "networth": "20948",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "isaacmuwanguzi180@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1550",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nabaasaedwin3@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1550",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nabaasaedwin3@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "947",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "5500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "aruhocanan@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1008",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "5500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "Nkojjodavid19@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1006",
        "payment_method": "personal",
        "deposit_amount": "8000",
        "networth": "8800",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "Nkojjonelson25@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1619",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "24255",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "faiswalub10@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1608",
        "payment_method": "personal",
        "deposit_amount": "500",
        "networth": "607",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "desirebirungi5@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "169",
        "payment_method": "personal",
        "deposit_amount": "1000",
        "networth": "1050",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "imuwanguzi616@gmail.com",
        "updated": "Jun",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1322",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2100",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mandelabarungi@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "0",
        "payment_method": "personal",
        "deposit_amount": "8000",
        "networth": "20948",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "isaacmuwanguzi180@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1358",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "cjkiwana9@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1550",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nabaasaedwin3@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1358",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "cjkiwana9@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1550",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nabaasaedwin3@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "0",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "20948",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "isaacmuwanguzi180@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1550",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nabaasaedwin3@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "433",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "23100",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "2 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nakiguliagnes16@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1358",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "cjkiwana9@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "169",
        "payment_method": "personal",
        "deposit_amount": "3000",
        "networth": "3150",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1\r\n",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "imuwanguzi616@gmail.com",
        "updated": "Feb",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1322",
        "payment_method": "personal",
        "deposit_amount": "3000",
        "networth": "3300",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "mandelabarungi@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "68",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "21000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nalongoreginahmirembe@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1509",
        "payment_method": "personal",
        "deposit_amount": "1000",
        "networth": "1426",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "lowenah10@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1550",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nabaasaedwin3@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "0",
        "payment_method": "personal",
        "deposit_amount": "9000",
        "networth": "10948",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "isaacmuwanguzi180@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1509",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2426",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "lowenah10@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1358",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "6064",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "cjkiwana9@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "40",
        "payment_method": "personal",
        "deposit_amount": "1500",
        "networth": "21000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nakityobarbrs@gmail.com",
        "updated": "May",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "191",
        "payment_method": "personal",
        "deposit_amount": "15000",
        "networth": "16191",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "eyerfortunate@gmail.com",
        "updated": "Mar",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1118",
        "payment_method": "personal",
        "deposit_amount": "4000",
        "networth": "4620",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "jerryembati6@gmail.com",
        "updated": "Apr",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "0",
        "payment_method": "personal",
        "deposit_amount": "7500",
        "networth": "10000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "isaacmuwanguzi180@gmail.com",
        "updated": "Apr",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1008",
        "payment_method": "personal",
        "deposit_amount": "3200",
        "networth": "3520",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "Nkojjodavid19@gmail.com",
        "updated": "Apr",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1008",
        "payment_method": "personal",
        "deposit_amount": "3200",
        "networth": "3520",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "Nkojjodavid19@gmail.com",
        "updated": "Apr",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1008",
        "payment_method": "personal",
        "deposit_amount": "3200",
        "networth": "3520",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "Nkojjodavid19@gmail.com",
        "updated": "Apr",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1006",
        "payment_method": "personal",
        "deposit_amount": "8000",
        "networth": "8800",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "Nkojjonelson25@gmail.com",
        "updated": "Apr",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1117",
        "payment_method": "personal",
        "deposit_amount": "2500",
        "networth": "10395",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "opiocharles81@gmail.com",
        "updated": "Apr",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "897",
        "payment_method": "personal",
        "deposit_amount": "40000",
        "networth": "44000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "egonamek112@gmail.com",
        "updated": "Apr",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1008",
        "payment_method": "personal",
        "deposit_amount": "3000",
        "networth": "3300",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "Nkojjodavid19@gmail.com",
        "updated": "Apr",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1008",
        "payment_method": "personal",
        "deposit_amount": "3000",
        "networth": "3300",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "Nkojjodavid19@gmail.com",
        "updated": "Apr",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1117",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "10395",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "opiocharles81@gmail.com",
        "updated": "Apr",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1242",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2310",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "cissynabimanya@gmail.com",
        "updated": "Apr",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "777",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2310",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "newlexasmart@gmail.com",
        "updated": "Apr",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "843",
        "payment_method": "personal",
        "deposit_amount": "2000",
        "networth": "2310",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "kbrgmichael@gmail.com",
        "updated": "Apr",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "40",
        "payment_method": "personal",
        "deposit_amount": "3000",
        "networth": "21000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nakityobarbrs@gmail.com",
        "updated": "May",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1117",
        "payment_method": "personal",
        "deposit_amount": "9000",
        "networth": "10395",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "opiocharles81@gmail.com",
        "updated": "Jun",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "599",
        "payment_method": "personal",
        "deposit_amount": "23500",
        "networth": "28500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "abbeykintu100@gmail.com",
        "updated": "Jun",
        "done": false,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "0",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "10948",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "isaacmuwanguzi180@gmail.com",
        "updated": "Jun",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "0",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "20948",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "isaacmuwanguzi180@gmail.com",
        "updated": "Jun",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "40",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "21000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nakityobarbrs@gmail.com",
        "updated": "Jun",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "40",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "21000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nakityobarbrs@gmail.com",
        "updated": "Jul",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "40",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "21000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nakityobarbrs@gmail.com",
        "updated": "Jun",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "0",
        "payment_method": "personal",
        "deposit_amount": "19000",
        "networth": "20948",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "isaacmuwanguzi180@gmail.com",
        "updated": "Aug",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "59",
        "payment_method": "personal",
        "deposit_amount": "28000",
        "networth": "33957",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "cyanasesavers@gmail.com",
        "updated": "Aug",
        "done": false,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "0",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "20948",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "isaacmuwanguzi180@gmail.com",
        "updated": "Aug",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "0",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "20948",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "isaacmuwanguzi180@gmail.com",
        "updated": "Aug",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "40",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "21000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nakityobarbrs@gmail.com",
        "updated": "Sep",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "767",
        "payment_method": "personal",
        "deposit_amount": "15000",
        "networth": "20000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "2",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "haronmandre0@gmail.com",
        "updated": "Sep",
        "done": false,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1",
        "payment_method": "personal",
        "deposit_amount": "220000",
        "networth": "660000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "wasswaviannie@gmail.com",
        "updated": "Nov",
        "done": false,
        "class_id": "4",
        "date": "2022"
    },
    {
        "user_id": "976",
        "payment_method": "personal",
        "deposit_amount": "9000",
        "networth": "9500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nassolomercyjorine2020@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1",
        "payment_method": "personal",
        "deposit_amount": "45000",
        "networth": "135000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "wasswaviannie@gmail.com",
        "updated": "Dec",
        "done": false,
        "class_id": "3",
        "date": "2022"
    },
    {
        "user_id": "1",
        "payment_method": "personal",
        "deposit_amount": "43000",
        "networth": "129000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "5 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "wasswaviannie@gmail.com",
        "updated": "Dec",
        "done": false,
        "class_id": "4",
        "date": "2022"
    },
    {
        "user_id": "1639",
        "payment_method": "personal",
        "deposit_amount": "3000",
        "networth": "3150",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "justinemiracle24@gmail.com",
        "updated": "Dec",
        "done": true,
        "class_id": "0",
        "date": "2022"
    },
    {
        "user_id": "1022",
        "payment_method": "personal",
        "deposit_amount": "600000",
        "networth": "663500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "basajjaadan@gmail.com",
        "updated": "Jan",
        "done": false,
        "class_id": "0",
        "date": "2023"
    },
    {
        "user_id": "976",
        "payment_method": "personal",
        "deposit_amount": "192000",
        "networth": "193000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nassolomercyjorine2020@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2023"
    },
    {
        "user_id": "1639",
        "payment_method": "personal",
        "deposit_amount": "3000",
        "networth": "3150",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "",
        "available": "1",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "justinemiracle24@gmail.com",
        "updated": "Jan",
        "done": true,
        "class_id": "0",
        "date": "2023"
    },
    {
        "user_id": "1022",
        "payment_method": "personal",
        "deposit_amount": "100000",
        "networth": "103000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "basajjaadan@gmail.com",
        "updated": "Jan",
        "done": false,
        "class_id": "0",
        "date": "2023"
    },
    {
        "user_id": "976",
        "payment_method": "personal",
        "deposit_amount": "9000",
        "networth": "9500",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "2",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nassolomercyjorine2020@gmail.com",
        "updated": "Mar",
        "done": false,
        "class_id": "0",
        "date": "2023"
    },
    {
        "user_id": "0",
        "payment_method": "personal",
        "deposit_amount": "",
        "networth": "",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "",
        "available": "2",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "isaacmuwanguzi180@gmail.com",
        "updated": "Mar",
        "done": false,
        "class_id": "0",
        "date": "2023"
    },
    {
        "user_id": "1690",
        "payment_method": "personal",
        "deposit_amount": "5000",
        "networth": "",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "jsentomero@gmail.com",
        "updated": "Mar",
        "done": false,
        "class_id": "0",
        "date": "2021"
    },
    {
        "user_id": "976",
        "payment_method": "personal",
        "deposit_amount": "9000",
        "networth": "10000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "2",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nassolomercyjorine2020@gmail.com",
        "updated": "Mar",
        "done": false,
        "class_id": "0",
        "date": "2023"
    },
    {
        "user_id": "976",
        "payment_method": "personal",
        "deposit_amount": "20000",
        "networth": "22000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "",
        "available": "",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nassolomercyjorine2020@gmail.com",
        "updated": "May",
        "done": false,
        "class_id": "0",
        "date": "2023"
    },
    {
        "user_id": "1",
        "payment_method": "personal",
        "deposit_amount": "4000",
        "networth": "12000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "0",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "wasswaviannie@gmail.com",
        "updated": "Apr",
        "done": false,
        "class_id": "0",
        "date": "2023"
    },
    {
        "user_id": "976",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "10600",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "2",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nassolomercyjorine2020@gmail.com",
        "updated": "May",
        "done": false,
        "class_id": "0",
        "date": "2023"
    },
    {
        "user_id": "976",
        "payment_method": "personal",
        "deposit_amount": "10000",
        "networth": "10400",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "",
        "available": "",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nassolomercyjorine2020@gmail.com",
        "updated": "Jun",
        "done": false,
        "class_id": "0",
        "date": "2023"
    },
    {
        "user_id": "976",
        "payment_method": "personal",
        "deposit_amount": "27500",
        "networth": "28000",
        "account_type": "basic",
        "currency": "UGX",
        "investment_period": "1 ",
        "available": "2",
        "reference": "",
        "txt_reference": "Cyanase_test",
        "email": "nassolomercyjorine2020@gmail.com",
        "updated": "Jun",
        "done": false,
        "class_id": "0",
        "date": "2023"
    }
]

export const groupedData1 = [
    {
        "name": "2020",
        "data": [
            {
                "x": "Jan",
                "y": 0
            },
            {
                "x": "Feb",
                "y": 0
            },
            {
                "x": "Mar",
                "y": 0
            },
            {
                "x": "Apr",
                "y": 54000
            },
            {
                "x": "May",
                "y": 160000
            },
            {
                "x": "Jun",
                "y": 150000
            },
            {
                "x": "Jul",
                "y": 445000
            },
            {
                "x": "Aug",
                "y": 1000
            },
            {
                "x": "Sep",
                "y": 100000
            },
            {
                "x": "Oct",
                "y": 10000
            },
            {
                "x": "Nov",
                "y": 0
            },
            {
                "x": "Dec",
                "y": 65550
            },
        ]
    },
    {
        "name": "2021",
        "data": [
            {
                "x": "Jan",
                "y": 0
            },
            {
                "x": "Feb",
                "y": 0
            },
            {
                "x": "Mar",
                "y": 207000
            },
            {
                "x": "Apr",
                "y": 600000
            },
            {
                "x": "May",
                "y": 0
            },
            {
                "x": "Jun",
                "y": 52000
            },
            {
                "x": "Jul",
                "y": 0
            },
            {
                "x": "Aug",
                "y": 242000
            },
            {
                "x": "Sep",
                "y": 40000
            },
            {
                "x": "Oct",
                "y": 11360
            },
            {
                "x": "Nov",
                "y": 707927.5
            },
            {
                "x": "Dec",
                "y": 595013
            }
        ]
    },
    {
        "name": "2022",
        "data": [
            {
                "x": "Jan",
                "y": 342800
            },
            {
                "x": "Feb",
                "y": 222005
            },
            {
                "x": "Mar",
                "y": 153000
            },
            {
                "x": "Apr",
                "y": 88600
            },
            {
                "x": "May",
                "y": 4500
            },
            {
                "x": "Jun",
                "y": 83500
            },
            {
                "x": "Jul",
                "y": 40000
            },
            {
                "x": "Aug",
                "y": 67000
            },
            {
                "x": "Sep",
                "y": 35000
            },
            {
                "x": "Oct",
                "y": 0
            },
            {
                "x": "Nov",
                "y": 890000
            },
            {
                "x": "Dec",
                "y": 100000
            }
        ]
    },
    {
        "name": "2023",
        "data": [
            {
                "x": "Jan",
                "y": 895000
            },
            {
                "x": "Feb",
                "y": 0
            },
            {
                "x": "Mar",
                "y": 18000
            },
            {
                "x": "Apr",
                "y": 4000
            },
            {
                "x": "May",
                "y": 30000
            },
            {
                "x": "Jun",
                "y": 37500
            }
        ]
    }
]

export const cummulative = [
    {
        "name": "2020",
        "data": [
            {
                "x": "Jan",
                "y": 0
            },
            {
                "x": "Feb",
                "y": 0
            },
            {
                "x": "Mar",
                "y": 0
            },
            {
                "x": "Apr",
                "y": 54000
            },
            {
                "x": "May",
                "y": 214000
            },
            {
                "x": "Jun",
                "y": 364000
            },
            {
                "x": "Jul",
                "y": 809000
            },
            {
                "x": "Aug",
                "y": 810000
            },
            {
                "x": "Sep",
                "y": 910000
            },
            {
                "x": "Oct",
                "y": 920000
            },
            {
                "x": "Nov",
                "y": 920000
            },
            {
                "x": "Dec",
                "y": 985550
            },
        ]
    },
    {
        "name": "2021",
        "data": [
            {
                "x": "Jan",
                "y": 0
            },
            {
                "x": "Feb",
                "y": 0
            },
            {
                "x": "Mar",
                "y": 156680
            },
            {
                "x": "Apr",
                "y": 660680
            },
            {
                "x": "May",
                "y": 660680
            },
            {
                "x": "Jun",
                "y": 323680
            },
            {
                "x": "Jul",
                "y": -162320
            },
            {
                "x": "Aug",
                "y": 79680
            },
            {
                "x": "Sep",
                "y": 119680
            },
            {
                "x": "Oct",
                "y": 131040
            },
            {
                "x": "Nov",
                "y": 665967.5
            },
            {
                "x": "Dec",
                "y": 1260980.5
            }
        ]
    },
    {
        "name": "2022",
        "data": [
            {
                "x": "Jan",
                "y": 342800
            },
            {
                "x": "Feb",
                "y": 564805
            },
            {
                "x": "Mar",
                "y": 717805
            },
            {
                "x": "Apr",
                "y": 806405
            },
            {
                "x": "May",
                "y": 810905
            },
            {
                "x": "Jun",
                "y": 894405
            },
            {
                "x": "Jul",
                "y": 934405
            },
            {
                "x": "Aug",
                "y": 1000355
            },
            {
                "x": "Sep",
                "y": 1030355
            },
            {
                "x": "Oct",
                "y": 1023355
            },
            {
                "x": "Nov",
                "y": 937915
            },
            {
                "x": "Dec",
                "y": 973747
            }
        ]
    },
    {
        "name": "2023",
        "data": [
            {
                "x": "Jan",
                "y": 317160
            },
            {
                "x": "Feb",
                "y": 269320
            },
            {
                "x": "Mar",
                "y": 287320
            },
            {
                "x": "Apr",
                "y": 291320
            },
            {
                "x": "May",
                "y": 321320
            },
            {
                "x": "Jun",
                "y": 358820
            }
        ]
    }
]