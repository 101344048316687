import Carousel from "react-multi-carousel";
import "../node_modules/react-multi-carousel/lib/styles.css";
import './App.css'
import Deposit from "./Accounts/Deposit";
import Modal from "react-bootstrap/Modal";
import { ChevronLeft, ChevronRight } from "react-iconly";
import { useState } from "react";

function MultiCarousel4(props){
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const [set, setSet] = useState("")
    const [description, setDescription] = useState("")
    const [logo, setLogo] = useState("")
    const [id, setId] = useState("")
    const [options, setOption] = useState()
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          partialVisibilityGutter: 40 // this is needed to tell the amount of px that should be visible.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          partialVisibilityGutter: 210 // this is needed to tell the amount of px that should be visible.
        }
    }
    const getId = (clas, description, id, logo, options) => {
        setId(id)
        setSet(clas)
        setDescription(description)
        setLogo(logo)
        setOption(options)
        handleShow1()
    }
    const investmentClasses = () => {
        return(
            <Carousel partialVisible={true} rtl={false ? true : undefined} responsive={responsive} className="p-2">
            {
                props.investmentClasses.map((option, id) => (
                    <div className="p-3 rounded-4 bg-white shadows tab-nav mx-ml" key={id} onClick={() => getId(option.investment_class, option.description, option.investment_class_id, option.logo, option.investment_options)}>
                        <div className="d-flex flex-row">
                        <img src={option.logo} width={40} height={50} alt="logo" className="rounded-circle"/>
                        <h5 className="text-end bluey wider bolder">
                        <h6 className="text-end bolder small">{option.investment_options.length}</h6>{option.investment_class}</h5></div>
                    </div>
                ))
            }
            </Carousel>
        )
    }
    return(<div>
        {investmentClasses()}
        <Modal show = { show1 }
            onHide = { handleClose1 }
            dialogClassName = "my-modal1" >
            <Deposit
            country = { props.country }
            lastname = { props.name }
            email = { props.email }
            phone = { props.phone }
            setClass={set}
            setDescription = {description}
            setLogo = {logo}
            setId = {id}
            setOptions = {options}
        riskAnalysisPercentages = {props.riskAnalysisPercentages}
        verification = {props.verification}
        complete = {props.complete}
        /> </Modal>
    </div>)
}

export default MultiCarousel4;